(<template>
  <div class="assignment-print-info">
    <p class="assignment-print-info__title">{{ printTitle }}</p>
    <div class="assignment-print-info__wrapper">
      <p class="assignment-print-info__date">{{ printDate }}</p>
      <p class="assignment-print-info__details"
         v-html="detailsInfo"></p>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    props: {
      jobType: {
        type: String,
        default() {
          return 'assignment';
        }
      }
    },
    data() {
      return {
        infoPortalText: this.$getString('domainData', 'infoPortalText'),
        infoPortalLink: this.$getString('domainData', 'infoPortalLink')
      };
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        jobId: (state) => state.storeJobObj.id || ''
      }),
      printTitle() {
        const template = this.$gettext('Booked #%{ n }');
        if (this.jobType === 'assignment') {
          return this.$gettextInterpolate(template, {n: this.jobId});
        }
        if (this.jobType === 'oneproject') {
          return this.$gettextInterpolate(template, {n: this.$route.params.id});
        }
        return this.$gettextInterpolate(template, {n: '-'});
      },
      printDate() {
        const template = this.$gettext('Printed on: %{ n } @ %{ m }');
        return this.$gettextInterpolate(template, {n: this.$moment().format('DD/MM/YYYY'), m: this.$moment().format('HH:mm')});
      },
      detailsInfo() {
        const template = this.$gettext('For the latest details visit <a href="%{link}">%{portal}</a>');
        return this.$gettextInterpolate(template, {link: this.infoPortalLink, portal: this.infoPortalText});
      }
    }
  };
</script>

<style scoped>
  .assignment-print-info {
    display: none;
    width: 100%;
    padding: 10px 15px;
    background: #ff5b24;
    background: var(--color-secondary);
    color: #fff;
  }

  .assignment-print-info__title,
  .assignment-print-info__wrapper {
    display: inline-block;
    vertical-align: middle;
  }

  .assignment-print-info__title {
    font-size: 18px;
  }

  .assignment-print-info__wrapper {
    margin-left: auto;
    font-weight: bold;
    font-size: 10px;
    text-align: right;
  }
</style>
